<div class="slidebar" [ngStyle]="isDarkTheme ?{'background-color':'#212529'} : {'background-color': '#f5f7fc'}">
  <ul>
    <li class="logo" >
      <a >
        <span class="icon"><img src="assets\icon.png" class="logo-icon"></span>
        <span class="text">BLACK LOTUS LABS BY LUMEN</span>
      </a>
    </li>
    <li>
      <a >
        <span class="icon"><i class="bi bi-graph-down"></i></span>
        <span [routerLink]="'/analytics'" class="text">Home</span>
      </a>
    </li>
<!--    <li>-->
<!--      <a >-->
<!--        <span class="icon"><i class="bi bi-eyeglasses"></i></span>-->
<!--        <span [routerLink]="'/overview'" class="text">About Us</span>-->
<!--      </a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a >-->
<!--        <span class="icon"><i class="bi bi-shield-lock"></i></span>-->
<!--        <span class="text">Security</span>-->
<!--      </a>-->
<!--    </li>-->
    <li>
      <a>
        <span class="icon"><i class="bi bi-megaphone"></i></span>
        <span [routerLink]="'/newsboard'" class="text">About Us</span>
      </a>
    </li>
    <li>
      <a >
        <span class="icon" (click)="toggleMode()"><i [class]="'bi ' +(!isDarkTheme ? 'bi-moon-fill' : 'bi-brightness-high')"></i></span>
        <span class="text" (click)="toggleMode()">{{isDarkTheme ? 'Light Mode':'Dark Mode'}}</span>
      </a>
    </li>
    <li>
        <span [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}" class="footer">{{app_version}}</span>
    </li>
  </ul>
</div>

