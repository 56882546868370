import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
//service to broadcast dark mode flag to other components
export class ToggleDarkModeService {
 private toggle_mode_subject:BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
 public selected_mode$ = this.toggle_mode_subject.asObservable();

  constructor() {

  }

  //set dark-light themes
  toggleMode(){
    const current_mode = this.toggle_mode_subject.getValue();
    this.toggle_mode_subject.next(!current_mode);
  }
}
