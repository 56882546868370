import {Component, ElementRef} from '@angular/core';
import {ToggleDarkModeService} from "../../Shared/toggle-dark-mode.service";
import * as am5 from "@amcharts/amcharts5";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  isDarkTheme:boolean = false;
  constructor(private helper:ToggleDarkModeService, private ele:ElementRef) {
  }
  ngOnInit() {
    this.helper.selected_mode$.subscribe(mode =>{
      this.isDarkTheme = mode;
    })
  }
}
