import {AfterViewInit, Component, ElementRef} from '@angular/core';
import {ToggleDarkModeService} from "../../Shared/toggle-dark-mode.service";

@Component({
  selector: 'app-milestones',
  templateUrl: './milestones.component.html',
  styleUrls: ['./milestones.component.css']
})
export class MilestonesComponent {
  isDarkTheme:boolean = false;
  constructor(private helper:ToggleDarkModeService) {
  }
  ngOnInit() {
    this.helper.selected_mode$.subscribe(mode => {
      this.isDarkTheme = mode;
    })
  }
}
