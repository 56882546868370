import {Component, OnInit} from '@angular/core';
import package_json from "../../../../package.json";
import {ActivatedRoute} from "@angular/router";
import {ToggleDarkModeService} from "../../Shared/toggle-dark-mode.service";

@Component({
  selector: 'app-news-board',
  templateUrl: './news-board.component.html',
  styleUrls: ['./news-board.component.css']
})
export class NewsBoardComponent  implements  OnInit{
  isDarkTheme:boolean = false;

  constructor(private  helper :ToggleDarkModeService) {}
  ngOnInit() {
    this.helper.selected_mode$.subscribe(mode =>{
      this.isDarkTheme = mode;

    })
  }


}
