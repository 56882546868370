import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {env} from "../environment";



//common api service for http requests
@Injectable({
  providedIn: 'root'
})
//common service to call http methods
export class ApiService {
  constructor(private http:HttpClient) { }

  public get<T>(end_point:string): Observable<T>{
      const api_url = env.url_base + end_point;
      console.log(api_url);
    return this.http.get<T>(api_url).pipe(
      catchError(err => {
        console.log("Api request error",err);
        return throwError(err);
      })
    );
  }

  public post(end_point:string, post_data: any): Observable<any>{
    const url = `${end_point}`;
    return this.http.post(url,post_data).pipe(
      catchError(err => {
        console.log("Api request error",err);
        return throwError(err);
      })
    );
  }
}
