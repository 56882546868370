export class Image_model{
  constructor(
    public id:number,
    public redirect_url:string,
    public content:string,
    public sub_content:string,
    public url:string,
    public altText:string
  ) {
  }
}
