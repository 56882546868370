import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {OverviewDashboardComponent} from "./Overview_Dashboard/overview-dashboard/overview-dashboard.component";
import {AnalyticsDashboardComponent} from "./Analytics/analytics-dashboard/analytics-dashboard.component";
import {BodyContentResolverService} from "./Overview_Dashboard/image-slider-carousel/body-content-resolver.service";
import {HeaderResolverService} from "./Overview_Dashboard/header-carousel/header-resolver.service";
import {NewsBoardComponent} from "./Newsroom/news-board/news-board.component";

const routes: Routes = [
  {path:'', redirectTo:'/analytics',pathMatch:'full'},
  {path:'overview',component:OverviewDashboardComponent},
  {path:'analytics',component:AnalyticsDashboardComponent},
  {path:'newsboard',component:NewsBoardComponent},
  {path:'**',component:AnalyticsDashboardComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

