 <div class="container" data-aos="fade-down"  data-aos-easing="linear"   data-aos-duration="1500"  [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}">
   <div class="milestones">
     <div class="heading">
       <span>MILESTONES</span>
     </div>
     <div class="sub-heading">
       <span>Stand aware of accomplishments</span>
     </div>
     <div class="milestone-grid">
       <div class="grid-item">
         <div class="value1"></div>
         <div class="paragraph-details">C2s tracked daily</div>
       </div>
       <div class="grid-item">
         <div class="value2"><span class="span">M</span></div>
         <div class="paragraph-details">DNS queries collected per day for continuous learning</div>
       </div>
       <div class="grid-item">
         <div class="value3"><span class="span">B+</span></div>
         <div class="paragraph-details">NetFlow sessions monitored daily</div>
       </div>

     </div>
   </div>

  </div>


