
<div class="container" data-aos="fade-down"  data-aos-easing="linear"   data-aos-duration="1500" [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}">
  <div class="footer">
    <div class="icon"><img src="assets\icon.png" class="logo-icon"></div>
    <div class="quick-links">
      <div class="links-group">
        <div class="mission">Our mission is to leverage our network visibility to both help protect our customers and keep the internet clean.</div>
      </div>
      <div class="links-group">
        <span>NAVIGATION</span>
        <div >
          <a [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}" href="#">About Us</a>
          <a [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}" href="#">Services</a>
          <a [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}" href="#">Stats</a>
          <a [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}" href="#">News</a>
        </div>
      </div>
      <div class="links-group">
        <span>RESOURCES</span>
        <div>
          <a [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}" href="#">Blog</a>
          <a [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}" href="#">Customer Portals</a>
          <a [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}" href="#">Support</a>
          <a [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}" href="#">Careers</a>
        </div>
      </div>
      <div class="links-group">
        <span>SOLUTIONS</span>
        <div>
          <a [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}" href="#">Mission</a>
          <a [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}" href="#">Security</a>
          <a [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}" href="#">Network Maps</a>
          <a [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}" href="#">Contact</a>
        </div>
      </div>
      <div class="links-group">
        <span>SOCIALS</span>
        <div class="social-media">
          <a href="https://www.facebook.com/LumenTechnologies/">
            <i style="color: #4f98ba"class="bi bi-facebook"></i>
          </a>
          <a href="https://twitter.com/i/flow/login">
            <i style="color: cornflowerblue" class="bi bi-twitter"></i>
          </a>
          <a href="https://www.youtube.com/lumentechnologies">
            <i style="color: red" class="bi bi-youtube"></i>
          </a>
          <a href="https://www.linkedin.com/company/lumentechnologies/mycompany/">
            <i style="color: cornflowerblue"class="bi bi-linkedin"></i></a>
        </div>
      </div>

    </div>
  </div>


  </div>


