<div class="container" data-aos="fade-down" data-aos-duration="1500" [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}">
    <div class="updates">
        <div class="heading">
            <span>LATEST</span>
        </div>
        <div class="sub-heading">
            <span class="news_title">Black Lotus Labs At Work</span>
        </div>
        <div class="container-1">
            <div class="img-1"></div>
            <div class="content-one" [ngStyle]="isDarkTheme ?{'background':'#212529'} : {'background': '#f5f7fc'}">
                <p class="heading">No Rest For The Wicked: HiatusRAT Takes Little Time Off In A Return To Action</p>
                <p>In March 2023, Lumen Black Lotus Labs reported on a complex campaign called “HiatusRAT” that infected over 100 edge
                    networking devices globally.</p>
                <a [ngStyle]="!isDarkTheme ?{'color':'#212529','border-color':'#212529'} : {'background': '#f5f7fc','border-color':'#f5f7fc'}"
                   target="_blank" href="https://blog.lumen.com/hiatusrat-takes-little-time-off-in-a-return-to-action/">Learn More</a>
            </div>
        </div>
        <div class="container-1">
            <div class="img-2"></div>
            <div class="content-one" [ngStyle]="isDarkTheme ?{'background':'#212529'} : {'background': '#f5f7fc'}">
                <p class="heading">Routers From The Underground: Exposing AVrecon</p>
                <p>Lumen Black Lotus Labs® identified another multi-year campaign involving compromised routers across the globe.</p>
                <a [ngStyle]="!isDarkTheme ?{'color':'#212529','border-color':'#212529'} : {'background': '#f5f7fc','border-color':'#f5f7fc'}"
                   target="_blank" href="https://blog.lumen.com/routers-from-the-underground-exposing-avrecon/">Learn More</a>
            </div>
        </div>
        <div class="container-1">
            <div class="img-3"></div>
            <div class="content-one" [ngStyle]="isDarkTheme ?{'background':'#212529'} : {'background': '#f5f7fc'}">
                <p class="heading">Qakbot: Retool, Reinfect, Recycle</p>
                <p>Qakbot (aka Pinkslipbot, Qbot) has persisted as a banking trojan – then a potent malware/ransomware distribution network –
                    for well over a decade, its origins going back as far as 2007.</p>
                <a [ngStyle]="!isDarkTheme ?{'color':'#212529','border-color':'#212529'} : {'background': '#f5f7fc','border-color':'#f5f7fc'}"
                   target="_blank" href="https://blog.lumen.com/qakbot-retool-reinfect-recycle/">Learn More</a>
            </div>
        </div>
        <div class="container-1">
            <div class="img-4"></div>
            <div class="content-one" [ngStyle]="isDarkTheme ?{'background':'#212529'} : {'background': '#f5f7fc'}">
                <p class="heading">New HiatusRAT Router Malware Covertly Spies On Victims</p>
                <p>Just nine months after discovering ZuoRAT – a novel malware targeting small office/home office (SOHO) routers – Lumen Black
                    Lotus Labs® identified another, never-before-seen campaign involving compromised routers.</p>
                <a [ngStyle]="!isDarkTheme ?{'color':'#212529','border-color':'#212529'} : {'background': '#f5f7fc','border-color':'#f5f7fc'}"
                   target="_blank" href="https://blog.lumen.com/new-hiatusrat-router-malware-covertly-spies-on-victims/">Learn More</a>
            </div>
        </div>
        <div class="container-1">
            <div class="img-5"></div>
            <div class="content-one" [ngStyle]="isDarkTheme ?{'background':'#212529'} : {'background': '#f5f7fc'}">
                <p class="heading">CLDAP Reflectors On The Rise Despite Best Practice</p>
                <p>The sophistication of threat actors’ DDoS strategy and tactics continues to evolve rapidly in response to improved
                    mitigation-side efforts.</p>
                <a [ngStyle]="!isDarkTheme ?{'color':'#212529','border-color':'#212529'} : {'background': '#f5f7fc','border-color':'#f5f7fc'}"
                   target="_blank" href="https://blog.lumen.com/cldap-reflectors-on-the-rise-despite-best-practice/">Learn More</a>
            </div>
        </div>
        <div class="container-1">
            <div class="img-6"></div>
            <div class="content-one" [ngStyle]="isDarkTheme ?{'background':'#212529'} : {'background': '#f5f7fc'}">
                <p class="heading">Chaos Is A Go-Based Swiss Army Knife Of Malware</p>
                <p>The prevalence of malware written in Go programming language has increased dramatically in recent years due to its
                    flexibility, low antivirus detection rates and difficulty to reverse-engineer.</p>
                <a [ngStyle]="!isDarkTheme ?{'color':'#212529','border-color':'#212529'} : {'background': '#f5f7fc','border-color':'#f5f7fc'}"
                   target="_blank" href="https://blog.lumen.com/chaos-is-a-go-based-swiss-army-knife-of-malware/">Learn More</a>
            </div>
        </div>
    </div>

</div>



