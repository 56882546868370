import {Component, ComponentFactoryResolver, Input, ViewChild} from '@angular/core';
import {PopupInfoComponent} from "../popup-info/popup-info.component";
import {PlaceholderDirective} from "../../Shared/placeholder.directive";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-top-metrics',
  templateUrl: './top-metrics.component.html',
  styleUrls: ['./top-metrics.component.css']
})
export class TopMetricsComponent {
  @Input() top_threat_data : any;
  @ViewChild(PlaceholderDirective, { static: false }) infoHost: PlaceholderDirective | undefined;
  private subs: Subscription | undefined;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

//Loading the modal and rendering it on click of cards
  public showInfo(message: any) {
    var msg;
    const attack = "If a host is confirmed doing something malicious, but can’t be attributed to a port scan, malware distribution, spam, phish, or a part of a botnet, then it's considered an attack. Black Lotus Labs general attack data shows threats that are poking around the Lumen global network, although they might not be actively trying to steal something.";
    const c2 = "A command and control server (C2) allows threat actors to communicate with compromised devices over a network so they can launch and control cyber-attacks.";
    const bot = "A bot is an internet-connected device, like a laptop, server, or IoT device, that has been infected by malware is under the control of a bad actor.\n";
    const phish = "This is a host associated with sending emails designed to trick you into providing credentials or other personal information by emulating a trustworthy source. Entries could also end up on this list by being a landing page for a phish.";
    const malware = "Malware is a broad category of threats traditionally known as viruses, worms, Trojans, exploits, etc. These entries are either hosting or sending malicious software. If malware is on your system, then it could slow your system and open you up to ransomware and data leaks.";
    const scan = "A scanning attack looks for weak points in a network or system, either by port scanning large portions of the Internet, looking for a specific exploit, or enumerating versions of software. A scan does not always mean there's an imminent threat, but it could indicate that attackers are looking for vulnerabilities to exploit to gain access.";
    const spam = "Spam is an unsolicited email trying to sell something or provide clicks for profit back to a URL. There may not be an immediate risk from the spam, but the host is often either backdoored or connecting to the internet in an unnatural way.\n" +
        "\n";
    const popular = "Popular IPs are associated with traffic for high volume websites, mail servers, etc. for well known services. These are typically thought of as benign.";
    const proxy = "Proxies route traffic through a third party, which could have benign or malicious uses. When combined with other indicators of malicious activity, proxies could point to a threat.";
    const ransomware_payments = "This is an IP address used to collect ransomware funds, which is trackable by Black Lotus Labs. Though it probably won’t point directly to the attacker, seeing this on a network shows those devices have been compromised.";
    const newly_registered_domains = "Although not necessarily a threat in and of itself, a large uptick in newly registered domains could indicate a threat. Monitoring newly registered domains allows Black Lotus Labs to detect and track suspicious domains that could be involved in malicious activities.";
    const reflector = "Reflector attacks leverage a third-party component to send attack traffic to a victim, hiding the attacker's identity and making the attack difficult to mitigate. When Black Lotus Labs identifies a reflector, then that network could be vulnerable to denial of service (DoS) attacks, which can cause downtime.";

    switch (message.friendly) {
      case 'attack': {
        msg = attack;
        break;
      }
      case 'c2': {
        msg = c2;
        break;
      }
      case 'bot': {
        msg = bot;
        break;
      }
      case 'phish': {
        msg = phish;
        break;
      }
      case 'malware': {
        msg = malware;
        break;
      }
      case 'scan': {
        msg = scan;
        break;
      }
      case 'spam': {
        msg = spam;
        break;
      }
      case 'popular': {
        msg = popular;
        break;
      }
      case 'proxy': {
        msg = proxy;
        break;
      }
      case 'ransomware_payments': {
        msg = ransomware_payments;
        break;
      }
      case 'newly_registered_domains': {
        msg = newly_registered_domains;
        break;
      }
      case 'reflector': {
        msg = reflector;
        break;
      }
      default: {
        msg = 'No Data Currently Available.';
        break;
      }
    }
    //This resolver allows us to create an  instance of component without having to use a new keyword
    const popupCmpFactory = this.componentFactoryResolver.resolveComponentFactory(
      PopupInfoComponent
    );
    // @ts-ignore
    const hostViewContainerRef = this.infoHost.viewContainerRef;
    hostViewContainerRef.clear();

    const componentRef = hostViewContainerRef.createComponent(popupCmpFactory);

    componentRef.instance.message = msg;
    this.subs = componentRef.instance.close.subscribe(() => {
      // @ts-ignore
      this.subs.unsubscribe();
      hostViewContainerRef.clear();
    });
  }
  //close subscription for popup
  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
