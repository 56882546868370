import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavigationComponent } from './Side_Navigation/side-navigation.component';
import { OverviewDashboardComponent } from './Overview_Dashboard/overview-dashboard/overview-dashboard.component';
import { AnalyticsDashboardComponent } from './Analytics/analytics-dashboard/analytics-dashboard.component';
import { HeaderCarouselComponent } from './Overview_Dashboard/header-carousel/header-carousel.component';
import { ImageSliderCarouselComponent } from './Overview_Dashboard/image-slider-carousel/image-slider-carousel.component';
import {HttpClientModule} from "@angular/common/http";
import { FooterComponent } from './Overview_Dashboard/footer/footer.component';
import {TechnologyServicesComponent} from "./Overview_Dashboard/technology-services/technology-services.component";
import {DumbellChartComponent} from "./Analytics/dumbell-chart/dumbell-chart.component";
import {DonutChartComponent} from "./Analytics/donut-chart/donut-chart.component";
import {MapComponent} from "./Analytics/map/map.component";
import { MilestonesComponent } from './Overview_Dashboard/milestones/milestones.component';
import { NewsBoardComponent } from './Newsroom/news-board/news-board.component';
import { TeamComponent } from './Newsroom/team/team.component';
import { TopMetricsComponent } from './Analytics/top-metrics/top-metrics.component';
import {MapWithAnimationComponent} from "./Analytics/map-with-animation/map-with-animation.component";
import { BarChartComponent } from './Analytics/bar-chart/bar-chart.component';
import { PopupInfoComponent } from './Analytics/popup-info/popup-info.component';
import { PlaceholderDirective } from './Shared/placeholder.directive';





@NgModule({
  declarations: [
    AppComponent,
    SideNavigationComponent,
    AnalyticsDashboardComponent,
    OverviewDashboardComponent,
    HeaderCarouselComponent,
    ImageSliderCarouselComponent,
    FooterComponent,
    TechnologyServicesComponent,
    DumbellChartComponent,
    DonutChartComponent,
    MapComponent,
    MilestonesComponent,
    NewsBoardComponent,
    TeamComponent,
    TopMetricsComponent,
    MapWithAnimationComponent,
    BarChartComponent,
    PopupInfoComponent,
    PlaceholderDirective,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  schemas: [
      CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
