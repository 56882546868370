import {Component, Input, OnChanges, OnInit} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5themeDark from "@amcharts/amcharts5/themes/Dark"
import {ToggleDarkModeService} from "../../Shared/toggle-dark-mode.service";
import {DarkTheme} from "@amcharts/amcharts5/.internal/themes/DarkTheme";
import {color, Root} from "@amcharts/amcharts5";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

@Component({
    selector: 'app-donut-chart',
    templateUrl: './donut-chart.component.html',
    styleUrls: ['./donut-chart.component.css']
})
export class DonutChartComponent implements OnInit, OnChanges {
    @Input() total_threat_data: any;
    isDarkTheme: boolean = false;
    root: any;
    chart: any;
    series: any;
    modal: any;
    first_launch: boolean = true;
    private temp_data: any;

    constructor(private helper: ToggleDarkModeService) {
    }

    ngOnInit() {
        this.root = am5.Root.new("donutdiv");
        this.modal = am5.Modal.new(this.root, {
            content: "No data available<p>Select different timeline</p>",

        });
        this.loadData();
        this.helper.selected_mode$.subscribe(mode => {
            this.isDarkTheme = mode;
            this.change_theme(this.root);

        })
    }

    ngOnChanges() {
        if (!this.first_launch) this.reloadData();
    }

    private loadData() {
        this.root.setThemes([
            am5themes_Animated.new(this.root)
        ]);


        this.chart = this.root.container.children.push(am5percent.PieChart.new(this.root, {
            layout: this.root.verticalLayout,
            innerRadius: 70,
            outerRadius: 75
        }));


        this.series = this.chart.series.push(am5percent.PieSeries.new(this.root, {
                valueField: "count",
                categoryField: "friendly",
            })
        );
        let exporting = am5plugins_exporting.Exporting.new(this.root, {
            menu: am5plugins_exporting.ExportingMenu.new(this.root, {})
        });
        //series.labels.template.set("fill",am5.color("#f4f5f7"));
        this.series.labels.template.set("fontSize", "10px");
        // series.ticks.template.set("stroke",am5.color("#f4f5f7"));

        if (this.total_threat_data.length <= 0) {
            this.modal.open();
        } else {
            if (this.modal) this.modal.close();
        }
        if (this.total_threat_data.length >= 11) {
            this.temp_data = this.total_threat_data.splice(0, 10);
        } else {
            this.temp_data = this.total_threat_data;
        }
        this.series.data.setAll(this.temp_data);
        this.series.appear(2000, 500);
        this.first_launch = false;
    }


    private change_theme(root: Root) {
        if (this.isDarkTheme) {
            root.setThemes([
                DarkTheme.new(root)
            ]);

        } else {
            root.setThemes([
                am5themes_Animated.new(root)
            ]);
        }
    }

    private reloadData() {
        if (this.total_threat_data.length <= 0) {
            this.modal.open();
        } else {
            if (this.modal) this.modal.close();
        }
        this.series.data.setAll(this.total_threat_data);
        this.series.appear(2000, 500);

    }
}
