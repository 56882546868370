import {Component, OnInit} from '@angular/core';
import {ToggleDarkModeService} from "../Shared/toggle-dark-mode.service";
import package_json  from '../../../package.json';


@Component({
  selector: 'app-Side_Navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.css']
})
export class SideNavigationComponent implements  OnInit{
  isDarkTheme:boolean = false;
  public app_version:string = package_json.version;
 constructor(private helper:ToggleDarkModeService) {
 }
ngOnInit() {
  this.helper.selected_mode$.subscribe(mode =>{
    this.isDarkTheme = mode;
  })
}
 //dark mode enable/disable
  toggleMode(){
   this.helper.toggleMode();
 }
}
