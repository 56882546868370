import {Component, ElementRef, OnInit} from '@angular/core';
import {ToggleDarkModeService} from "./Shared/toggle-dark-mode.service";
import AOS from "aos";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements  OnInit {
  isDarkTheme: boolean = false;

  constructor(private helper: ToggleDarkModeService, private ele: ElementRef) {
  }

  ngOnInit() {
    AOS.init(); // for animation purpose

    //Flag to indicate dark mode has been selected
    this.helper.selected_mode$.subscribe(mode => {
      this.isDarkTheme = mode;
      if(this.isDarkTheme)this.ele.nativeElement.ownerDocument.body.style.backgroundColor = '#212529';
      else  this.ele.nativeElement.ownerDocument.body.style.backgroundColor = '#f5f7fc';

    })
  }
}
