import { Component } from '@angular/core';
import {ToggleDarkModeService} from "../../Shared/toggle-dark-mode.service";

@Component({
  selector: 'app-technology-services',
  templateUrl: './technology-services.component.html',
  styleUrls: ['./technology-services.component.css']
})
export class TechnologyServicesComponent {
  isDarkTheme:boolean = false;

  constructor(private helper:ToggleDarkModeService) {

  }
  ngOnInit() {
    this.helper.selected_mode$.subscribe(mode =>{
      this.isDarkTheme = mode;
    })
  }
}
