<div #contentToPrint>
    <div class="container" data-aos="fade-down-left" id="print-section" data-aos-duration="2500"
         [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}">
        <div class="analytics">
            <i style="align-self: flex-end" class="bi bi-printer-fill" (click)="saveAsPDF()"></i>

            <div class="overview">
                <div class="left">
                    <h2>Top Five Threats</h2>
                    <p class="overview_total">Total Threat IP's Recorded: {{total_threats | number}}</p>
                    <p class="overview_total">Total Record's Scanned: {{total_records | number}}</p>
                </div>
                <div class="right">
                    <button class="chi-btn -xs" [class.active]="timeline === 'last 24 hours'" (click)="change_time_period('last 24 hours')">Last
                        24 hrs
                    </button>
                    <button class="chi-btn -xs" [class.active]="timeline === 'last 7 days'" (click)="change_time_period('last 7 days')">Last 7
                        days
                    </button>
                    <button class="chi-btn -xs" [class.active]="timeline === 'last 30 days'" (click)="change_time_period('last 30 days')">Last
                        30 days
                    </button>
                    <i style="align-self: end" [class]="'bi ' +(minimizeMetrics ? 'bi-arrows-angle-expand' : 'bi-dash-circle')"
                       (click)="minimizedMetrics()"></i>
                </div>
            </div>
            <app-top-metrics *ngIf="!minimizeMetrics" [top_threat_data]="top_five_ips"></app-top-metrics>
            <div class="chart-container" data-aos="fade-down-right" data-aos-duration="1500">
                <div class="chart-heading">Overall threats Seen in {{timeline}}</div>
                <div class="icon-container">
                    <i [class]="'bi ' +(minimizeChart ? 'bi-arrows-angle-expand' : 'bi-dash-circle')" (click)="minimizedChart()"></i>
                    <i [class]="'bi ' +(minimizeBar ? 'bi-arrows-angle-expand' : 'bi-dash-circle')" (click)="minimizedBar()"></i>
                </div>
                <div *ngIf="dataLoaded && (minimizedBar || minimizedChart)" class="stats">
                    <div *ngIf="!minimizeChart" class="pie-container">
                        <app-donut-chart [total_threat_data]="total_threat_list"></app-donut-chart>
                    </div>
                    <div *ngIf="!minimizeBar" class="bar-container">
                        <app-bar-chart [bar_threat_data]="top_ten_ips"></app-bar-chart>
                    </div>
                </div>
            </div>
            <div *ngIf="show_threat_map" class="map-container">
                <div class="map-heading">Cyber-security Threat Map
                    <i style="float: right" [class]="'bi ' +(minimizeMap ? 'bi-arrows-angle-expand' : 'bi-dash-circle')"
                       (click)="minimizedMap()"></i>
                </div>
                <app-map-with-animation *ngIf="!minimizeMap" [threat_map_list]="threat_map_list"></app-map-with-animation>
            </div>

            <div>
                <div class="thesis_overview">Black Lotus Labs Threat Detection & Mitigation
                    <i class="icon-single" style="font-size: 20px;" [class]="'bi ' +(minimizeThesis ? 'bi-arrows-angle-expand' : 'bi-dash-circle')" (click)="minimizedThesis()"></i>
                </div>
                <div *ngIf="!minimizeThesis" class="card card-padding">
                    <div class="card-body thesis_body">Lumen’s threat research team Black Lotus Labs uses its deep visibility into the expansive global Lumen
                        backbone to identify and validate new threats, often before others can see them. Lumen embeds this powerful threat intelligence
                        into Rapid Threat Defense—the auto-blocking feature in the Lumen Security portfolio—to protect our customers from malicious
                        traffic. We see more, so we can stop more.
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


