<div class="container" data-aos="fade-down" data-aos-duration="1500">
  <div class="heading">
    <span>Meet our leaders</span>
  </div>
  <div class="sub-heading">
    <span>Meet our team of experts in security development and threat research</span>
  </div>
  <div class="profile-blocks" data-aos="fade-right" data-aos-duration="2500">
  <div class="profile">
    <figure>
      <div class="team-images">
      <img src="https://qph.cf2.quoracdn.net/main-qimg-f32f85d21d59a5540948c3bfbce52e68-lq" alt="">
      <div class="overlay">
        <div class="personal-info text-center">
          <p>
            Director of Threat Intelligence
          </p>
          <div class="socials">
            <a href="#"><i class="bi bi-facebook"></i></a>
            <a href="#"><i class="bi bi-twitter"></i></a>
            <a href="#"><i class="bi bi-google"></i></a>
            <a href="#"><i class="bi bi-envelope"></i></a>
          </div>
        </div>
      </div>
      </div>
      <figcaption>Ron Gill</figcaption>
    </figure>
  </div>

 <!-- <div class="profile">
    <figure>
      <img src="https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="">
      <figcaption>Tony Sanchez</figcaption>
    </figure>
  </div>

  <div class="profile">
    <figure>
      <img src="https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="">
      <figcaption>Arthi Selvaraj</figcaption>
    </figure>
  </div>
  </div>-->
</div>





