import {Component, OnInit} from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from '@amcharts/amcharts5/xy';
import {Root} from "@amcharts/amcharts5";
import {ToggleDarkModeService} from "../../Shared/toggle-dark-mode.service";
import {DarkTheme} from "@amcharts/amcharts5/.internal/themes/DarkTheme";

@Component({
  selector: 'app-dumbell-chart',
  templateUrl: './dumbell-chart.component.html',
  styleUrls: ['./dumbell-chart.component.css']
})
export class DumbellChartComponent implements OnInit {
  isDarkTheme: boolean = false;

  constructor(private helper: ToggleDarkModeService) {
  }

  ngOnInit() {
   let root = am5.Root.new("dumbelldiv");
    this.loadData(root);
    this.helper.selected_mode$.subscribe(mode => {
      this.isDarkTheme = mode;
      this.change_theme(root);
    })
  }

  private loadData(root: Root) {
      root.setThemes([
        am5themes_Animated.new(root)
      ]);
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true
      }));
// Add cursor
// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineY.set("visible", false);


// Create axes
// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 30
      });
      xRenderer.labels.template.setAll({
        rotation: -90,
        centerY: am5.p50,
        centerX: 0
      });

      xRenderer.grid.template.setAll({
        visible: false
      })


      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "category",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {})
      }));


      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "close",
        openValueYField: "open",
        categoryXField: "category",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{openValueY} - {valueY}"
        })
      }));

      series.columns.template.setAll({
        width: 0.5
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Circle.new(root, {
            radius: 5,
            fill: series.get("fill")
          })
        })
      })


      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: am5.Circle.new(root, {
            radius: 5,
            fill: series.get("fill")
          })
        })
      })


// Set data
      let data = [];
      let open = 100;
      let close = 120;

      let names = [
        "phish",
        "c2s",
        "reflector",
        "malware",
        "popular",
        "attack",
        "spam",
        "proxy"
      ];

      for (var i = 0; i < names.length; i++) {
        open += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 5);
        close = open + Math.round(Math.random() * 10) + 3;
        data.push({
          category: names[i],
          open: open,
          close: close
        });
      }

      xAxis.data.setAll(data);
      series.data.setAll(data);


      series.appear(1000);
      chart.appear(1000, 100);


    }


  private change_theme(root: Root) {
    if(this.isDarkTheme){
      root.setThemes([
        DarkTheme.new(root)
      ]);

    }
    else{
      root.setThemes([
        am5themes_Animated.new(root)
      ]);
    }
  }
}
