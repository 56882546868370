<div class="overview" >
  <div class="overview_content">
    <div class="overview_card" *ngFor="let data of top_threat_data" (click)="showInfo(data)">
      <div class="overview_top"  data-aos="fade-left"  data-aos-duration="2500">
        <div>
          <p>{{data.friendly}}</p>
        </div>
      </div>
      <p class="overview_num" data-aos="fade-left"  data-aos-duration="2500">{{data.count | number}}</p>
      <p class="overview_text" data-aos="fade-left"  data-aos-duration="2500">recorded</p>
    </div>
  </div>
</div>
<!--popup template-->
<ng-template appPlaceholder></ng-template>
