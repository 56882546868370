import {Component, OnInit} from '@angular/core';
import {Image_model} from "./image_model";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-header-carousel',
  templateUrl: './header-carousel.component.html',
  styleUrls: ['./header-carousel.component.css']
})
export class HeaderCarouselComponent {
  image_list: Image_model[] = [
    new Image_model(1,
      'https://www.lumen.com/en-us/security/black-lotus-labs.html',
      'Discover what\'s Latest on BLACK LOTUS?!',
      'An official threat research arm of Lumen. Our mission is to leverage our network visibility\n to both help protect our customers and keep the internet clean.',
      'assets/stats-bg.jpg',
      'About Black Lotus'),
    new Image_model(2,
      'https://blog.lumen.com/new-hiatusrat-router-malware-covertly-spies-on-victims/?utm_source=black+lotus+labs+webpage&utm_medium=referral',
      'We SEE threats that other\'s CANT',
      'New HiatusRAT Router Malware Covertly Spies on Victims.\n Lumen Black Lotus Labs identified a new campaign involving compromised routers.',
      'assets/HiatusRAT.jpg',
      'Threat Detection'),
    new Image_model(3,
      'https://blog.lumen.com/qakbot-retool-reinfect-recycle/?utm_source=black+lotus+labs+webpage&utm_medium=referral',
      'Retool, REINFECT, Recycle',
      'Black Lotus Labs’ global visibility,has tracked Qakbot’s campaigns to observe the network structure,\n and gained key insights into the methods that support Qakbot’s reputation as an evasive and tenacious threat',
      'assets/Qakbot.jpg',
      'About Qakbot'),


  ]

//Subscribe to resolved data for header carousel or assign default data
/*  constructor(private route:ActivatedRoute) {
  let data = this.route.snapshot.data['headerContent'];
   if(data != null){
     this.image_list = data;
   }

 }*/

}
