<div class="container" data-aos="fade-down"  data-aos-easing="linear"   data-aos-duration="1500">
  <div class="service">
    <div class="heading">
      <span>SERVICES</span>
    </div>
    <div class="sub-heading">
      <span>Cutting edge technology behind our platform</span>
    </div>
    <div class="blocks">
      <div class="service-card">
        <div class="block-icon">
          <i class="bi bi-unlock"></i>
        </div>
        <div class="service-title" >
          <h3>CVAS</h3>
          <p>
            To ensure all Lumen products, processes and services are secure, scrutinized and monitored. Utilizing industry standards, best practices,
            and the most sophisticated cybersecurity solutions available to market,
            CVAS guards Lumen's infrastructure from malicious actors.
          </p>
        </div>
      </div>
      <div  class="service-card" style="background-color: #8bbfd5">
        <div class="block-icon">
          <i class="bi bi-envelope-check"></i>
        </div>
        <div  class="service-title">
          <h3>Email Protection</h3>
          <p>
            Email Protection Services continually adds new patterns to its database to protect against newly discovered spam, phishing and
            malware. You can help by reporting missed suspicious email. When you
            report an email as suspicious, our systems will reevaluate the email, improving our detections and preventing that email from reaching other employees.
          </p>
        </div>
      </div>
      <div  class="service-card" style="background-color: #8bbfd5">
        <div class="block-icon">
          <i class="bi bi-android"></i>
        </div>
        <div class="service-title">
          <h3>Physical Security</h3>
          <p>
            Responds to alarms, monitors video and events, supports facility access out of business hours, and serves as a central point of contact for all security related event.esign and support for physical security systems, hardware, and devices
            Project Management: initiate, plan, execute, and oversee physical security projects.Facility site access control and badging team
          </p>
        </div>
      </div>
    </div>
  </div>

  </div>



