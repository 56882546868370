import {Component, EventEmitter, Input, Output} from '@angular/core';
import package_json from "../../../../package.json";
import {ActivatedRoute} from "@angular/router";
import {ToggleDarkModeService} from "../../Shared/toggle-dark-mode.service";

@Component({
  selector: 'app-popup-info',
  templateUrl: './popup-info.component.html',
  styleUrls: ['./popup-info.component.css']
})
export class PopupInfoComponent {
  //msg for popup
  @Input() message: string | undefined;
  //event to capture closing the popup
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<void>();
  isDarkTheme = false;

  constructor(private route:ActivatedRoute, private  helper :ToggleDarkModeService) {


  }

  ngOnInit() {
    this.helper.selected_mode$.subscribe(mode =>{
      this.isDarkTheme = mode;
    })
  }


  onClose() {
    this.close.emit();
  }
}
