import {Component, Input, OnInit} from '@angular/core';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5map from "@amcharts/amcharts5/map";
import * as am5 from "@amcharts/amcharts5";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import {ApiService} from "../../Shared/api.service";
import {ToggleDarkModeService} from "../../Shared/toggle-dark-mode.service";
import {Root} from "@amcharts/amcharts5";
import {DarkTheme} from "@amcharts/amcharts5/.internal/themes/DarkTheme";
import {line} from "@amcharts/amcharts5/.internal/core/util/Draw";

interface Location {
    id: string,
    title: string,
    geometry: {
        type: string,
        coordinates: string[]
    },
    bulletSettings: {
        fill: any
    }
}

// noinspection TypeScriptValidateTypes
@Component({
    selector: 'app-map-with-animation',
    templateUrl: './map-with-animation.component.html',
    styleUrls: ['./map-with-animation.component.css']
})
export class MapWithAnimationComponent implements OnInit {
    @Input() threat_map_list: any;
    isDarkTheme: boolean = false;
    response_data: any = [];
    root: any;
    citySeries: any;
    chart: any;
    lineSeries: any;
    animatedBulletSeries: any;
    polygonSeries: any;
    animatedLineSeries: any;
    des: any = [];
    src: any = [];
    timeLabel: any;
    cities = [
        {
            id: "london",
            title: "London",
            geometry: {type: "Point", coordinates: [-0.1262, 51.5002]},
            bulletSettings: {
                fill: am5.color(0x8edd69)
            }
        },
        {
            id: "kazakhstan",
            title: "Kazakhstan",
            geometry: {type: "Point", coordinates: [48.019573, 66.923683]},
            bulletSettings: {
                fill: am5.color(0xff0000)
            }
        }];

    location_list: any[] = [];
    location_pairs: any[] = [];
    location_time: any[] = [];
    counter = 0

    // Data for map animations
    demo_location_list = [
        [
            {
                id: "paris",
                title: "Paris",
                geometry: {type: "Point", coordinates: [2.3510, 48.8567]},
                bulletSettings: {
                    fill: am5.color(0x8edd69)
                }
            }, {
            id: "moscow",
            title: "Moscow",
            geometry: {type: "Point", coordinates: [37.6176, 55.7558]},
            bulletSettings: {
                fill: am5.color(0xff0000)
            }
        }
        ],
        [
            {
                id: "paris",
                title: "Paris",
                geometry: {type: "Point", coordinates: [2.3510, 48.8567]},
                bulletSettings: {
                    fill: am5.color(0x8edd69)
                }
            },
            {
                id: "new york",
                title: "New York",
                geometry: {type: "Point", coordinates: [-74, 40.43]},
                bulletSettings: {
                    fill: am5.color(0xff0000)
                }
            }
        ],
        [
            {
                id: "new york",
                title: "New York",
                geometry: {type: "Point", coordinates: [-74, 40.43]},
                bulletSettings: {
                    fill: am5.color(0x8edd69)
                }
            },
            {
                id: "moscow",
                title: "Moscow",
                geometry: {type: "Point", coordinates: [37.6176, 55.7558]},
                bulletSettings: {
                    fill: am5.color(0xff0000)
                }
            }
        ]
    ];
    demo_location_pairs = [['paris', 'moscow'], ['paris', 'new york'], ['new york', 'moscow']];
    demo_location_time = ['2024-01-19 23:08:02', '2024-01-20 20:58:57', '2024-01-21 04:28:32']


    constructor(private api: ApiService, private helper: ToggleDarkModeService) {

    }


    ngOnInit() {
        this.root = am5.Root.new("chartmapwithanimation");
        this.helper.selected_mode$.subscribe(mode => {
            this.isDarkTheme = mode;
            this.change_theme(this.root);
        })
        this.loadData();


        // Interval that cycles through the list of different location pairs for threat map
        const interval = setInterval(() => {

            method1();

            // Check to see if we are at end of data array
            if (this.counter == this.threat_map_list.length-1) {
                // Reset counter
                this.counter = 0;
            } else {
                this.counter = this.counter + 1;
            }


        }, 8000);

        // Grabs the data for the location pairs and loads them on to the map
        const that = this;

        function method1() {
            // Set data points to render
            that.format_locations();

            that.citySeries.data.setAll(that.location_list[0]);
            that.load_animation(that.location_pairs[0]);
            if (that.timeLabel) {
                that.timeLabel.set('text', that.location_time[0])
            }
        }
    }

    private loadData() {

        const that = this;
        this.root.setThemes([
            am5themes_Animated.new(this.root)
        ]);

        // Checks for data, loads dummy data if any of the three lists are empty
        if (that.location_list.length !== 0 && that.location_pairs.length !== 0 && that.location_time.length !== 0) {
            // Do nothing, we have data
        } else {
            // Load dummy data, something was wrong with the API
            that.location_list = that.demo_location_list;
            that.location_pairs = that.demo_location_pairs;
            that.location_time = that.demo_location_time;
        }

        // Create the map chart
        // https://www.amcharts.com/docs/v5/charts/map-chart/
        this.chart = this.root.container.children.push(am5map.MapChart.new(this.root, {
            panX: "translateX",
            panY: "translateY",
            projection: am5map.geoMercator()
        }));

        let cont = this.chart.children.push(am5.Container.new(this.root, {
            layout: this.root.horizontalLayout,
            x: 20,
            y: 40
        }));


        // Add labels and controls
        // Label
        this.timeLabel = am5.Label.new(this.root, {
            text: "---- -- -- --:--:--",
            fontSize: 30,
            fontWeight: "500",
            fill: am5.color(0xff0000),
            y: am5.percent(98),
            x: am5.percent(0),
            centerY: am5.percent(100),
        });

        cont.children.push(am5.Label.new(this.root, {
            centerY: am5.p50,
            text: "Map"
        }));

        // String under legend on map
        this.chart.children.unshift(this.timeLabel);

        let switchButton = cont.children.push(am5.Button.new(this.root, {
            themeTags: ["switch"],
            centerY: am5.p50,
            icon: am5.Circle.new(this.root, {
                themeTags: ["icon"]
            })
        }));


        switchButton.on("active", function () {
            if (!switchButton.get("active")) {
                that.chart.set("projection", am5map.geoMercator());
                that.chart.set("panX", "translateX");
                that.chart.set("panY", "translateY");
            } else {
                that.chart.set("projection", am5map.geoOrthographic());
                that.chart.set("panX", "rotateX");
                that.chart.set("panY", "rotateY");
            }
        });

        cont.children.push(am5.Label.new(this.root, {
            centerY: am5.p50,
            text: "Globe"
        }));

        let legend = this.chart.children.push(am5.Legend.new(this.root, {
            nameField: "name",
            fillField: "color",
            strokeField: "color",
            y: am5.percent(85),
            centerY: am5.percent(100)

        }));

        legend.data.setAll([{
            name: "Source",
            color: am5.color(0xff0000),
            fill: this.root.interfaceColors.get("alternativeBackground")
        }, {
            name: "Destination",
            color: am5.color(0x8edd69),
            fill: this.root.interfaceColors.get("alternativeBackground")
        }]);

        // Create main polygon series for countries
        // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
        let polygonSeries = this.chart.series.push(am5map.MapPolygonSeries.new(this.root, {
            geoJSON: am5geodata_worldLow
        }));

        let graticuleSeries = this.chart.series.push(am5map.GraticuleSeries.new(this.root, {}));
        graticuleSeries.mapLines.template.setAll({
            stroke: this.root.interfaceColors.get("alternativeBackground"),
            strokeOpacity: 0.08
        });


        // this will be invisible line (note strokeOpacity = 0) along which invisible points will animate
        this.lineSeries = that.chart.series.push(am5map.MapLineSeries.new(that.root, {}));
        this.lineSeries.mapLines.template.setAll({
            stroke: that.root.interfaceColors.get("alternativeBackground"),
            strokeOpacity: 0,

        });

// this will be visible line. Lines will connect animating points so they will look like animated
        this.animatedLineSeries = that.chart.series.push(am5map.MapLineSeries.new(this.root, {}));
        this.animatedLineSeries.mapLines.template.setAll({
            stroke: am5.color(0xf6683d),
            strokeOpacity: 0.6,
            strokeDasharray: 3,
            strokeWidth: 4
        });

// destination series
        this.citySeries = this.chart.series.push(
            am5map.MapPointSeries.new(this.root, {})
        );


// visible city circles
        this.citySeries.bullets.push(function () {
            let circle = am5.Circle.new(that.root, {
                radius: 5,
                tooltipText: "{title}",
                tooltipY: 0,
                templateField: "bulletSettings",
                stroke: that.root.interfaceColors.get("background"),
                strokeWidth: 2
            });
            circle.animate({
                key: "radius",
                duration: 2000,
                to: 8,
                loops: Infinity,
                easing: am5.ease.yoyo(am5.ease.linear)
            })


            return am5.Bullet.new(that.root, {
                sprite: circle
            });
        });

// invisible series which will animate along invisible lines
        this.animatedBulletSeries = this.chart.series.push(
            am5map.MapPointSeries.new(this.root, {})
        );

        this.animatedBulletSeries.bullets.push(function () {
            let circle = am5.Circle.new(that.root, {
                radius: 0
            });

            return am5.Bullet.new(that.root, {
                sprite: circle
            });
        });
        polygonSeries.events.on("datavalidated", function () {
            that.chart.zoomToGeoPoint({
                longitude: -0.1262,
                latitude: 51.5002
            }, 3);
        });

        // this.updateData();
        this.citySeries.data.setAll(this.cities);
        this.load_animation(['london', 'kazakhstan']);
    }

    private load_animation(city: any) {
        let destinationDataItem: any = this.citySeries.getDataItemById(city[0]);
        let sourceDataItem: any = this.citySeries.getDataItemById(city[1]);
        let lineDataItem = this.lineSeries.pushDataItem({});
        lineDataItem.set("pointsToConnect", [sourceDataItem, destinationDataItem])

        let startDataItem: any = this.animatedBulletSeries.pushDataItem({});
        startDataItem.setAll({
            lineDataItem: lineDataItem,
            positionOnLine: 0
        });

        let endDataItem: any = this.animatedBulletSeries.pushDataItem({});
        endDataItem.setAll({
            lineDataItem: lineDataItem,
            positionOnLine: 1
        });

        let animatedLineDataItem = this.animatedLineSeries.pushDataItem({});
        animatedLineDataItem.set("pointsToConnect", [startDataItem, endDataItem])

        let lon0: any = sourceDataItem.get("longitude");
        let lat0: any = sourceDataItem.get("latitude");

        let lon1: any = destinationDataItem.get("longitude");
        let lat1: any = destinationDataItem.get("latitude");


        let distance = Math.hypot(lon1 - lon0, lat1 - lat0);
        let duration = distance * 100;
        animateStart(startDataItem, endDataItem, duration);

        function animateStart(startDataItem: any, endDataItem: any, duration: any) {
            let startAnimation = startDataItem.animate({
                key: "positionOnLine",
                from: 0,
                to: 1,
                duration: duration
            });

            startAnimation.events.on("stopped", function () {
                animateEnd(startDataItem, endDataItem, duration);
            });
        }

        function animateEnd(startDataItem: any, endDataItem: any, duration: any) {
            startDataItem.set("positionOnLine", 0)
            let endAnimation = endDataItem.animate({
                key: "positionOnLine",
                from: 0,
                to: 1,
                duration: duration
            })

            endAnimation.events.on("stopped", function () {
                animateStart(startDataItem, endDataItem, duration);
            });
        }

        this.chart.appear(1000, 100);
    }

    private change_theme(root: any) {
        if (this.isDarkTheme) {
            root.setThemes([
                DarkTheme.new(root)
            ]);

        } else {
            root.setThemes([
                am5themes_Animated.new(root)
            ]);
        }
    }

    private format_locations() {
        const data = this.threat_map_list;

        let temp_data = data[this.counter];
        let dest_data_structure: Location = {
                                    id: "",
                                    title: "",
                                    geometry: {
                                        type: "Point",
                                        coordinates: []
                                    },
                                    bulletSettings: {
                                        fill: am5.color(0x8edd69)
                                    }
                                    };
        let src_data_structure: Location =  {
                                    id: "",
                                    title: "",
                                    geometry: {
                                        type: "Point",
                                        coordinates: []
                                    },
                                    bulletSettings: {
                                        fill: am5.color(0xff0000)
                                    }
                                }

        // Fill in data
        dest_data_structure.id = temp_data.dest_city.id;
        dest_data_structure.title = temp_data.dest_city.title;

        dest_data_structure.geometry.coordinates.push(
            temp_data.dest_city.geometry.coordinates[0],
            temp_data.dest_city.geometry.coordinates[1],);

        src_data_structure.id = temp_data.source_city.id;
        src_data_structure.title = temp_data.source_city.title;

        src_data_structure.geometry.coordinates.push(
            temp_data.source_city.geometry.coordinates[0],
            temp_data.source_city.geometry.coordinates[1],);

        this.location_list = [[dest_data_structure, src_data_structure]];
        this.location_pairs = [[dest_data_structure.id, src_data_structure.id]];
        this.location_time = [temp_data.time_stamp];

    }
}

