import { Component } from '@angular/core';


@Component({
  selector: 'app-overview-dashboard',
  templateUrl: './overview-dashboard.component.html',
  styleUrls: ['./overview-dashboard.component.css']
})
export class OverviewDashboardComponent {

}
