<div class="container" data-aos="fade-down"  data-aos-easing="linear"   data-aos-duration="1500"  [ngStyle]="!isDarkTheme ?{'color':'#212529'} : {'color': '#f5f7fc'}">
  <div class="about">
    <div class="left">
      <span class="motto">MONITOR.DETECT.DEFEND</span>
      <h1>BLACK LOTUS LABS</h1>
      <hr>
      <p >Defenders of a clean internet, proactively disrupting ~150 C2s<br> per month through takedowns and notifications.</p>
      <a href="https://youtu.be/3lrjilOUFBc" target="_blank" class="btn btn-lg">Discover Black Lotus</a>
    </div>
    <div class="right">
      <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner" >
          <div class="carousel-item" *ngFor="let values of statisticalDataList; let i = index" [class.active]="i === 1">
            <div class="card">
              <img src="{{values.img}}" class="d-block w-100" alt="...">
              <div class="card-content">
                <p><b>{{values.count}}</b></p>
                <p>  {{values.content}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>








