import {Component, Input, OnChanges, OnInit} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5themeDark from "@amcharts/amcharts5/themes/Dark"
import {ToggleDarkModeService} from "../../Shared/toggle-dark-mode.service";
import {DarkTheme} from "@amcharts/amcharts5/.internal/themes/DarkTheme";
import {color, Root} from "@amcharts/amcharts5";

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements  OnInit {
  isDarkTheme: boolean = false;
  @Input() bar_threat_data : any;
  root:any ;
  chart:any;
  series:any;
  xRenderer:any;
  xAxis:any;
  modal:any;
  first_launch:boolean = true;


  constructor(private helper: ToggleDarkModeService) {
  }
  ngOnInit() {
    this.root = am5.Root.new("barchart");
    this.modal = am5.Modal.new(this.root, {
      content: "No data available<p>Select different timeline</p>",

    });
    this.loadData();
    this.helper.selected_mode$.subscribe(mode => {
      this.isDarkTheme = mode;
     this.change_theme(this.root);
    })
  }
  ngOnChanges() {
    if(!this.first_launch) this.reloadData();
  }

  private loadData() {
    const that= this;
// Create chart
// https://www.amcharts.com/docs/v5/charts/xy-chart/
    this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true
    }));

// Add cursor
// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {}));
    cursor.lineY.set("visible", false);


// Create axes
// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    this.xRenderer = am5xy.AxisRendererX.new(this.root, {minGridDistance: 30});
    this.xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15
    });

    this.xRenderer.grid.template.setAll({
      location: 1
    })

   this.xAxis = this.chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
      maxDeviation: 0.3,
      categoryField: "friendly",
      renderer: this.xRenderer,
      tooltip: am5.Tooltip.new(this.root, {})
    }));

    let yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
      maxDeviation: 0.3,
      renderer: am5xy.AxisRendererY.new(this.root, {
        strokeOpacity: 0.1
      })
    }));


// Create series
// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    this.series = this.chart.series.push(am5xy.ColumnSeries.new(this.root, {
      name: "Series 1",
      xAxis: this.xAxis,
      yAxis: yAxis,
      valueYField: "count",
      sequencedInterpolation: true,
      categoryXField: "friendly",
      tooltip: am5.Tooltip.new(this.root, {
        labelText: "{valueY}"
      })
    }));

    this.series.columns.template.setAll({cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0});
    this.series.columns.template.adapters.add("fill", function (fill:any, target:any) {
      return that.chart.get("colors").getIndex(that.series.columns.indexOf(target));
    });

    this.series.columns.template.adapters.add("stroke", function (stroke:any, target:any) {
      return that.chart.get("colors").getIndex(that.series.columns.indexOf(target));
    });

    if(this.bar_threat_data.length <= 0){
      this.modal.open();
    }
    else{
      if(this.modal) this.modal.close();
    }
    this.xAxis.data.setAll(this.bar_threat_data);
    this.series.data.setAll(this.bar_threat_data);
    this.series.appear(1000);
    this.chart.appear(1000, 100);
    this.first_launch = false;
  }

  private change_theme(root: Root) {
    if(this.isDarkTheme){
      root.setThemes([
        DarkTheme.new(root)
      ]);

    }
    else{
      root.setThemes([
        am5themes_Animated.new(root)
      ]);
    }
  }

  private reloadData() {
      if(this.bar_threat_data.length <= 0){
        this.modal.open();
      }
      else{
        if(this.modal) this.modal.close();
      }
    this.xAxis.data.setAll(this.bar_threat_data);
    this.series.data.setAll(this.bar_threat_data);
    this.series.appear(1000);
    this.chart.appear(1000, 100);

    }



}
