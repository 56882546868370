import {Component, OnInit} from '@angular/core';
import package_json from "../../../../package.json";
import {ToggleDarkModeService} from "../../Shared/toggle-dark-mode.service";
import {ActivatedRoute} from "@angular/router";
import {carousel_data} from "./carousel_data";

@Component({
  selector: 'app-image-slider-carousel',
  templateUrl: './image-slider-carousel.component.html',
  styleUrls: ['./image-slider-carousel.component.css']
})
export class ImageSliderCarouselComponent  implements  OnInit{
  statisticalDataList:carousel_data[] = [
    new carousel_data(1,
      'assets/st.jpg',
      'IP addresses sinkholed per day, across 400 different malware family variants',
      '4-5 MILLION'),
    new carousel_data(1,
      'assets/st.jpg',
      'IP addresses sinkholed per day, across 400 different malware family variants',
      '4-5 MILLION'),
    new carousel_data(1,
      'assets/st.jpg',
      'IP addresses sinkholed per day, across 400 different malware family variants',
      '4-5 MILLION')

  ]
  isDarkTheme:boolean = false;
  public app_version:string = package_json.version;

  //Subscribe to resolved data for body carousel or assign default data
  constructor(private route:ActivatedRoute, private  helper :ToggleDarkModeService) {
   /* let data = this.route.snapshot.data['statisticsData'];
    if(data != null){
      this.statisticalDataList = data;
    }*/

  }

  ngOnInit() {
    this.helper.selected_mode$.subscribe(mode =>{
      this.isDarkTheme = mode;
    })
  }
}
