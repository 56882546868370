<div id="carouselExampleSlidesOnly" data-aos="fade-down"   data-aos-duration="1500" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item" *ngFor="let img of image_list; let i = index" [class.active]="i === 1" [ngStyle]="{'background-image':'url(' +img.url + ')'}">
      <div class="carousel-caption" >
        <h3>{{img.content}}</h3>
        <p>{{img.sub_content}}</p>
        <a href= {{img.redirect_url}} target="_blank">ExploreMore!</a>
       </div>
    </div>
  </div>

