import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../Shared/api.service";
import {ToggleDarkModeService} from "../../Shared/toggle-dark-mode.service";
import {env} from "../../environment";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as am5 from "@amcharts/amcharts5";


@Component({
    selector: 'app-analytics-dashboard',
    templateUrl: './analytics-dashboard.component.html',
    styleUrls: ['./analytics-dashboard.component.css']
})

export class AnalyticsDashboardComponent implements OnInit {
    total_threat_list: any[] = [];
    threat_map_list: any[] = [];
    top_five_ips: any[] = [];
    top_ten_ips: any[] = [];
    dataLoaded: boolean = false;
    total_threats: number = 0;
    total_records: number = 0;
    show_threat_map = env.show_threat_map;
    minimizeBar: boolean = false;
    minimizeChart: boolean = false;
    minimizeMetrics: boolean = false;
    minimizeMap: boolean = false;
    minimizeThesis: boolean = false;
    timeline: string = 'last 24 hours';
    default_payload = [
        {"name": "ips_seen_as_c2s", "friendly": "c2s", "count": 10},
        {"name": "ips_seen_as_attack", "friendly": "attack", "count": 9},
        {"name": "ips_seen_as_spam", "friendly": "spam", "count": 8},
        {"name": "ips_seen_as_proxy", "friendly": "proxy", "count": 7},
        {"name": "ips_seen_as_bot", "friendly": "bot", "count": 6},
        {"name": "ips_seen_as_scan", "friendly": "scan", "count": 5},
        {"name": "ips_seen_as_reflector", "friendly": "reflector", "count": 4},
        {"name": "ips_seen_as_phish", "friendly": "phish", "count": 3},
        {"name": "ips_seen_as_malware", "friendly": "malware", "count": 2},
        {"name": "ips_seen_as_popular", "friendly": "popular", "count": 1}
    ]
    default_payload_5 = [
        {"name": "ips_seen_as_c2s", "friendly": "c2s", "count": 10},
        {"name": "ips_seen_as_attack", "friendly": "attack", "count": 9},
        {"name": "ips_seen_as_spam", "friendly": "spam", "count": 8},
        {"name": "ips_seen_as_proxy", "friendly": "proxy", "count": 7},
        {"name": "ips_seen_as_bot", "friendly": "bot", "count": 6},
    ]

    default_payload_map = [
            {
                "dest_source": [
                    "Viet Nam",
                    "United States"
                ],
                "dest_city": {
                    "id": "Viet Nam",
                    "title": "Viet Nam",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            "105.84",
                            "21.03"
                        ]
                    },
                    "bulletSettings": {
                        "fill": ""
                    }
                },
                "source_city": {
                    "id": "United States",
                    "title": "United States",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            "-74.02",
                            "40.79"
                        ]
                    },
                    "bulletSettings": {
                        "fill": ""
                    }
                },
                "time_stamp": "2024-01-20 23:58:32"
            },
            {
                "dest_source": [
                    "Korea, Republic of",
                    "United States"
                ],
                "dest_city": {
                    "id": "Korea, Republic of",
                    "title": "Korea, Republic of",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            "126.99",
                            "37.56"
                        ]
                    },
                    "bulletSettings": {
                        "fill": ""
                    }
                },
                "source_city": {
                    "id": "United States",
                    "title": "United States",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            "-117.82",
                            "34"
                        ]
                    },
                    "bulletSettings": {
                        "fill": ""
                    }
                },
                "time_stamp": "2024-01-20 23:58:32"
            },
            {
                "dest_source": [
                    "Viet Nam",
                    "United States"
                ],
                "dest_city": {
                    "id": "Viet Nam",
                    "title": "Viet Nam",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            "105.84",
                            "21.03"
                        ]
                    },
                    "bulletSettings": {
                        "fill": ""
                    }
                },
                "source_city": {
                    "id": "United States",
                    "title": "United States",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            "-74.02",
                            "40.79"
                        ]
                    },
                    "bulletSettings": {
                        "fill": ""
                    }
                },
                "time_stamp": "2024-01-20 23:58:32"
            },
            {
                "dest_source": [
                    "China",
                    "Finland"
                ],
                "dest_city": {
                    "id": "China",
                    "title": "China",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            "116.97",
                            "36.66"
                        ]
                    },
                    "bulletSettings": {
                        "fill": ""
                    }
                },
                "source_city": {
                    "id": "Finland",
                    "title": "Finland",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            "24.92",
                            "60.17"
                        ]
                    },
                    "bulletSettings": {
                        "fill": ""
                    }
                },
                "time_stamp": "2024-01-20 23:58:35"
            }
    ]

    isDarkTheme: boolean = false;
    @ViewChild('contentToPrint', {static: false}) contentToPrint!: ElementRef;

    constructor(private api: ApiService, private helper: ToggleDarkModeService) {

    }

    url: string = '';

    ngOnInit() {
        this.helper.selected_mode$.subscribe(mode => {
            this.isDarkTheme = mode;
            //changes to background color to adapt for dark mode
            try {
                this.contentToPrint.nativeElement.style.backgroundColor = this.isDarkTheme ? "#212529" : "#f5f7fc";
            } catch (error) {
                // Undefined element, ignore, DOM will catch up
            }

        })

        this.get_list_of_ips_seen();
    }

//Api call to get total-ips-seen for donut chart
    private get_list_of_ips_seen() {
        if (this.timeline == 'last 24 hours') {
            this.url = '/24-hours.json';

        } else if (this.timeline == 'last 7 days') {
            this.url = '/7-days.json';

        } else if (this.timeline == 'last 30 days') {
            this.url = '/30-days.json';

        }

        this.api.get(this.url).subscribe(
            (res: any) => {
                this.total_threats = (res.length > 0) ? res[0].total_threats : 0;
                this.total_records = (res.length > 0) ? res[0].total_records : 0;
                this.total_threat_list = (res.length > 1 && res[1].individual_threats.length > 0) ? res[1].individual_threats : [];
                this.threat_map_list = (res.length > 1 && res[2].threat_map.length > 0) ? res[2].threat_map : [];
                //this.threat_map_list = this.default_payload_map;

            },
            error => {
                this.total_threats = this.default_payload.length;
                this.total_threat_list = this.default_payload;
                this.top_five_ips = this.default_payload_5;
                this.top_ten_ips = this.default_payload;
                this.threat_map_list = this.default_payload_map;
                this.dataLoaded = true;
            },

            //filter top5 threats for single metric
            () => {
                this.getTopThreats(this.total_threat_list);
                this.get_top_ten_threats(this.total_threat_list);
                this.dataLoaded = true;
            }
        );
    }

    //sort and filter top 5 threats
    private getTopThreats(total_threat_list: any[]) {
        this.top_five_ips = total_threat_list.sort((a, b) => b.count - a.count).slice(0, Math.min(total_threat_list.length, 5));

    }

    // Highlight and set selected time range for showing data
    change_time_period(time_range: string) {
        this.timeline = time_range;
        this.get_list_of_ips_seen();
    }

    private get_top_ten_threats(total_threat_list: any[]) {
        this.top_ten_ips = total_threat_list.sort((a, b) => b.count - a.count).slice(0, Math.min(total_threat_list.length, 10));
    }

    //all minimize methods are for show/hide features
    public minimizedChart() {
        this.minimizeChart = !this.minimizeChart;
    }

    minimizedBar() {
        this.minimizeBar = !this.minimizeBar;
    }

    minimizedMetrics() {
        this.minimizeMetrics = !this.minimizeMetrics;
    }

    minimizedMap() {
        this.minimizeMap = !this.minimizeMap;
    }

    minimizedThesis() {
        this.minimizeThesis = !this.minimizeThesis;
    }

    saveAsPDF() {
        const content = this.contentToPrint.nativeElement;

        html2canvas(content).then((canvas) => {
            // Convert the canvas to an image
            const imgData = canvas.toDataURL('image/png');

            // Set the image dimensions to fit the PDF
            const imgWidth = 210; // A4 width in mm
            const pageHeight = imgWidth * 1.414; // A4 aspect ratio
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            // Create a new PDF document
            const doc = new jspdf.jsPDF('p', 'mm', 'a4');
            let position = 0;
            //changes to background color to adapt for dark mode
            if (this.isDarkTheme) {
                doc.setFillColor("#212529");

            } else {
                doc.setFillColor("#FFFFFF");

            }
            doc.rect(0, 0, 210, 297, 'F');
            // Add the image to the PDF
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            // Add more pages if necessary
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            const confirmSave = window.confirm('Do you want to save this page as pdf?');
            // Save the PDF
            if (confirmSave) {
                doc.save('charts.pdf');
            }
        });
    }


}
